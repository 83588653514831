import { Alert, AspectRatio, createStyles, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { CosmeticType, ImageCheckingStatus } from '@prisma/client';
import { IconAlertTriangle, IconShoppingBag, IconInfoCircle } from '@tabler/icons-react';
import { useMemo } from 'react';
import { RoutedDialogLink } from '~/components/Dialog/RoutedDialogProvider';
import { EdgeMedia } from '~/components/EdgeMedia/EdgeMedia';
import { useImagesContext } from '~/components/Image/Providers/ImagesProvider';
import { MediaHash } from '~/components/ImageHash/ImageHash';
import { MasonryCard } from '~/components/MasonryGrid/MasonryCard';
import { Reactions } from '~/components/Reaction/Reactions';
import { VotableTags } from '~/components/VotableTags/VotableTags';
import { ImagesInfiniteModel } from '~/server/services/image.service';
import HoverActionButton from '~/components/Cards/components/HoverActionButton';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { useInView } from '~/hooks/useInView';
import { HolidayFrame } from '~/components/Decorations/HolidayFrame';
import { truncate } from 'lodash-es';
import { constants } from '~/server/common/constants';
import { useImageStore } from '~/store/image.store';
import { ImageGuard2 } from '~/components/ImageGuard/ImageGuard2';
import { ImageContextMenu } from '~/components/Image/ContextMenu/ImageContextMenu';

export function ImagesCard({ data, width }: { data: ImagesInfiniteModel; width: number }) {
  const { ref, inView } = useInView({ rootMargin: '200% 0px' });
  const { classes, cx } = useStyles();
  const { images } = useImagesContext();

  const image = useImageStore(data);

  const isBlocked = image.ingestion === ImageCheckingStatus.Blocked;
  const isChecked = image.ingestion === ImageCheckingStatus.Checked;

  const tags = useMemo(() => {
    if (!image.tags) return undefined;
    return image.tags.filter((x) => x.type === 'Moderation');
  }, [image.tags]);

  const height = width * (image?.height ?? 1) / (image?.width ?? 1);

  const notPublished = image.publishedAt === null;

  const cardDecoration = image.user.cosmetics?.find(
    ({ cosmetic }) => cosmetic.type === CosmeticType.ContentDecoration,
  ) as (typeof image.user.cosmetics)[number] & {
    data?: { lights?: number; upgradedLights?: number };
  };

  return (
    <HolidayFrame {...cardDecoration}>
      <RoutedDialogLink name="imageDetail" state={{ type: image.type, imageId: image.id, images }}>
        <MasonryCard
          withBorder
          shadow="sm"
          p={0}
          height={height}
          ref={ref}
          // frameDecoration={image.cosmetic}
        >
          <AspectRatio
            className={classes.blurHash}
            ratio={(image?.width ?? 1) / (image?.height ?? 1)}
          >
            <MediaHash {...image} />
          </AspectRatio>

          <div className={classes.content}
               style={{ opacity: inView ? 1 : 0 }}>
            {inView && (
              <>
                <ImageGuard2 image={image}>
                  {(show) => (
                    <>
                      <Group
                        position="apart"
                        align="start"
                        spacing={4}
                        className="absolute top-2 left-2 right-2 z-10"
                        style={{ pointerEvents: 'none' }}
                      >
                        <Stack spacing="xs" ml="auto" sx={{ pointerEvents: 'auto' }}>
                          {!isBlocked && <ImageContextMenu image={image} />}
                          {image.deep_link && <HoverActionButton
                            label="Shop"
                            size={30}
                            color="gold"
                            variant="filled"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              window.open(image.deep_link);
                            }}
                          >
                            <IconShoppingBag stroke={2.5} size={16} />
                          </HoverActionButton>}
                          {notPublished && (
                            <Tooltip label="Not published">
                              <ThemeIcon size={30} radius="xl" variant="filled" color="yellow">
                                <IconAlertTriangle size={16} strokeWidth={2.5} />
                              </ThemeIcon>
                            </Tooltip>
                          )}
                        </Stack>
                      </Group>
                      {show && (
                        <EdgeMedia
                          src={image.url}
                          className={cx({ [classes.blocked]: isBlocked })}
                          name={image.name ?? image.id.toString()}
                          alt={image.name ?? undefined}
                          type={image.type}
                          width={450}
                          placeholder="empty"
                          style={{ width: '100%' }}
                          fadeIn
                        />
                      )}
                      <div className="footer-abs">
                        {!isBlocked ? <Group className={classes.info} spacing={4} position="apart" noWrap>
                          <Reactions
                            entityId={image.id}
                            entityType="image"
                            reactions={image.reactions}
                            metrics={{
                              likeCount: image.stats?.likeCountAllTime,
                              dislikeCount: image.stats?.dislikeCountAllTime,
                              heartCount: image.stats?.heartCountAllTime,
                              laughCount: image.stats?.laughCountAllTime,
                              cryCount: image.stats?.cryCountAllTime,
                              tippedAmountCount: image.stats?.tippedAmountCountAllTime,
                            }}
                            targetUserId={image.user.id}
                            className={classes.reactions}
                          />
                        </Group> : (
                          <Alert
                            color="red"
                            variant="filled"
                            radius={0}
                            className={classes.info}
                            title={
                              <Group spacing={4}>
                                <IconInfoCircle />
                                <Text inline>TOS Violation</Text>
                              </Group>
                            }
                          >
                            <Stack align="flex-end" spacing={0}>
                              <Text size="sm" inline>
                                The image you uploaded was determined to violate our TOS and will be
                                completely removed from our service.
                              </Text>
                            </Stack>
                          </Alert>)}
                      </div>
                    </>
                  )}
                </ImageGuard2>
              </>
            )}
          </div>
        </MasonryCard>
      </RoutedDialogLink>
    </HolidayFrame>
  );
}

const useStyles = createStyles((theme, _, getRef) => {
  const footerRef = getRef('footer');
  const infoRef = getRef('info');

  return {
    title: {
      lineHeight: 1.1,
      fontSize: 14,
      color: 'white',
      fontWeight: 500,
    },
    footer: {
      ref: footerRef,

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: theme.fn.gradient({
        from: 'rgba(37,38,43,0.8)',
        to: 'rgba(37,38,43,0)',
        deg: 0,
      }),
      backdropFilter: 'blur(5px) saturate(160%)',
      boxShadow: '0 -2px 6px 1px rgba(0,0,0,0.16)',
      zIndex: 10,
      gap: 6,
      padding: theme.spacing.xs,
    },
    reactions: {
      borderRadius: theme.radius.sm,
      background: theme.colorScheme === 'dark' ? theme.fn.rgba('#000', 0.31) : theme.colors.gray[0],
      backdropFilter: 'blur(2px) saturate(160%)',
      boxShadow: '0 -2px 6px 1px rgba(0,0,0,0.16)',
      padding: 4,
    },
    info: {
      ref: infoRef,

      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: 5,
    },
    blocked: { opacity: 0.3 },
    blurHash: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      opacity: 0.7,
    },
    content: {
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      height: '100%',
      opacity: 0,
      transition: theme.other.fadeIn,
    },

    link: {
      [`&:has(~ .frame-decor) .${footerRef}, &:has(~ .frame-decor) .${infoRef}`]: {
        paddingBottom: '36px !important',
      },
    },
  };
});
